"use strict";

(function (window, $) {
  // =================== * Header & Scroll Top * =================== //
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 100) {
      $('header').addClass('h-scroll');
      $('.scroll-to-top').fadeIn();
    } else {
      $('header').removeClass('h-scroll');
      $('.scroll-to-top').fadeOut();
    }
  });
  $('.scroll-to-top').on('click', function () {
    $('html, body').animate({
      scrollTop: 0
    }, 300);
    return false;
  });

  // =================== * Mobile menu * =================== //

  mMenu();
  $('.hamburger').on('click', function () {
    $('.nav-collapse.m-menu').slideToggle();
  });
  $(window).on('resize', function () {
    mMenu();
  });
  function mMenu() {
    $('.nav-collapse').removeClass('m-menu');
    if ($(window).width() < 1280) {
      $('.nav-collapse').addClass('m-menu');
    } else {
      $('.nav-collapse').removeClass('m-menu');
    }
  }
})(window, jQuery);

/**
 * Start Loading
 */
function startLoading() {
  var wrapElmnt = document.querySelector('.spinner-wrap');
  if (wrapElmnt) {
    wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) + 1;
  } else {
    wrapElmnt = document.createElement('div');
    wrapElmnt.classList.add('spinner-wrap');
    wrapElmnt.dataset.task = 1;
    var loadingElmnt = document.createElement('div');
    loadingElmnt.classList.add('spinner-border');
    var span = document.createElement('span');
    span.classList.add('sr-only');
    span.innerHTML = 'Loading...';
    loadingElmnt.appendChild(span);
    wrapElmnt.appendChild(loadingElmnt);
    var body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
    body.appendChild(wrapElmnt);
  }
}

/**
 * End Loading
 */
function endLoading() {
  var wrapElmnt = document.querySelector('.spinner-wrap');
  var taskCount = wrapElmnt.dataset.task;
  if (taskCount > 1) {
    wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) - 1;
  } else {
    wrapElmnt.style.opacity = 0;
    setTimeout(function () {
      wrapElmnt.remove();
    }, 300);
    var body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'auto';
  }
}